* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  background-position: center center !important;
  background-size: auto;
}

*::-webkit-scrollbar {
  display: none;
}
body,
html {
  /*  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh; */
  background-color: transparent;
}

.bg {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  background-position: center center;
  background-attachment: scroll !important;
  /*  background-image: url(./Assets/HomePage/IMG1.jpg); */
  background-size: auto;
  background-position-y: -5rem;
  background-repeat: no-repeat;

  animation: slide 160s ease-in-out 0s infinite normal;
  /* animation: change 160s infinite cubic-bezier(0.8, 1, 0, 1); */
}
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
}
.rsis-image {
  background-position: center center;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  background-size: auto;
}

/* @keyframes change {
  0% {
    background-image: url(./Assets/HomePage/IMG1.jpg);
  }
  20% {
    background-image: url(./Assets/IMG2.jpg);
  }
  40% {
    background-image: url(./Assets/IMG3.jpg);
  }
  60% {
    background-image: url(./Assets/IMG4.jpg);
  }
  80% {
    background-image: url(./Assets/IMG5.jpg);
  }
  100% {
    background-image: url(./Assets/HomePage/IMG1.jpg);
  }
} */

@keyframes change {
  0% {
    background-image: url(./Assets/HomePage/IMG1.jpg);
  }
  4% {
    background-image: url(./Assets/HomePage/IMG26.jpg);
  }
  8% {
    background-image: url(./Assets/HomePage/IMG2.jpg);
  }
  12% {
    background-image: url(./Assets/HomePage/IMG3.jpg);
  }
  16% {
    background-image: url(./Assets/HomePage/IMG4.jpg);
  }
  20% {
    background-image: url(./Assets/HomePage/IMG5.jpg);
  }
  24% {
    background-image: url(./Assets/HomePage/IMG6.jpg);
  }
  28% {
    background-image: url(./Assets/HomePage/IMG7.jpg);
  }
  32% {
    background-image: url(./Assets/HomePage/IMG8.jpg);
  }
  36% {
    background-image: url(./Assets/HomePage/IMG9.jpg);
  }
  40% {
    background-image: url(./Assets/HomePage/IMG10.jpg);
  }
  44% {
    background-image: url(./Assets/HomePage/IMG11.jpg);
  }
  48% {
    background-image: url(./Assets/HomePage/IMG12.jpg);
  }
  52% {
    background-image: url(./Assets/HomePage/IMG13.jpg);
  }
  56% {
    background-image: url(./Assets/HomePage/IMG14.jpg);
  }
  60% {
    background-image: url(./Assets/HomePage/IMG15.jpg);
  }
  64% {
    background-image: url(./Assets/HomePage/IMG16.jpg);
  }
  68% {
    background-image: url(./Assets/HomePage/IMG17.jpg);
  }
  72% {
    background-image: url(./Assets/HomePage/IMG18.jpg);
  }
  76% {
    background-image: url(./Assets/HomePage/IMG19.jpg);
  }
  80% {
    background-image: url(./Assets/HomePage/IMG20.jpg);
  }
  84% {
    background-image: url(./Assets/HomePage/IMG21.jpg);
  }
  88% {
    background-image: url(./Assets/HomePage/IMG22.jpg);
  }
  92% {
    background-image: url(./Assets/HomePage/IMG23.jpg);
  }
  96% {
    background-image: url(./Assets/HomePage/IMG24.jpg);
  }
  100% {
    background-image: url(./Assets/HomePage/IMG25.jpg);
  }
}

.home {
  width: 100vw;
  height: 100vh;
}
@media screen and (max-width: 960px) {
  .bg {
    width: 100vw;
    height: 100vh;
  }
}

@media screen and (max-width: 768px) {
  .bg {
    width: 100vw;
    height: 100vh;
  }
}
@media screen and (max-width: 900px) {
}

.Film¤ {
  background-position-y: -90px;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 6.25rem;
  height: 90vh;
  margin: 10;
}

.Film¤__img {
  height: 90%;
  object-fit: cover;
}

.Film¤__container {
  display: flex;
  flex-direction: column;
  height: 90%;
  object-fit: cover;
}

.img__container {
  display: flex;
  flex-direction: column;
  height: 90%;
  object-fit: cover;
}

.img__container h1 {
  color: #a1a6b4;
  font-size: 3.438rem;
  position: absolute;
  bottom: -10.625rem;
  left: 1rem;
}

.Film¤__txt {
  color: blue;

  position: absolute;
  top: -11.875rem;
}

.player {
  display: flex;
  height: 1980px;
  width: 1040px;
}

.vp-center {
  flex-wrap: wrap;
  display: flex;
}
